import { CustomDataDto, Error, LoginDto, ResponseDto } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'
import loginController from "../dao/loginController";
import axios from "axios";

export class LoginController<SecurityDataType = unknown> {
    http: HttpClient<SecurityDataType>

    constructor(http: HttpClient<SecurityDataType>) {
        this.http = http
    }

    /**
     * No description
     *
     * @tags login-controller
     * @name Login
     * @summary 로그인
     * @request POST:/login
     * @response `200` `LoginDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    login = (
        data: {
            /** 로그인 아이디 */
            email: string
            /** 로그인 암호 */
            password: string
        },
        params: RequestParams = {}
    ) =>
        this.http.request<LoginDto, Error>({
            path: `/user-service/login`,
            method: 'POST',
            body: data,
            ...params,
        })

    loginData = (
        data: {
            /** 로그인 아이디 */
            email: string
            /** 로그인 암호 */
            password: string
        },
        params: RequestParams = {}
    ) =>
        this.http.request<LoginDto, Error>({
            path: `/user-service/login/userData`,
            method: 'POST',
            body: data,
            ...params,
        })

    // 리프레쉬 토큰을 통한 엑세스 토큰 재발급
    refresh = (
        params: RequestParams = {}
    ) =>
        this.http.request<Error>({
            path: `/user-service/api/v1/users/token/refresh`,
            method: 'PUT',
            ...params,
        })

    // 이메일 인증코드 전송
    checkEmail = (
        query: {
            /** 이메일 */
            email: string
        },
        params: RequestParams = {}
    ) =>
        this.http.request<ResponseDto, Error>({
            path: `/user-service/api/v1/send-verification-email`,
            method: 'POST',
            query: query,
            ...params,
        })

    // 인증번호 검증
    verifyEmail = (
        query: {
            /** 이메일 정보 */
            emailVerificationId: string
            /** 인증번호 */
            code: string
        },
        params: RequestParams = {}
    ) =>
        this.http.request<ResponseDto, Error>({
            path: `/user-service/api/v1/verify-email-code`,
            method: 'POST',
            query: query,
            ...params,
        })

    /** 회원 가입 */
    join = (
        data: {
            /** 이름 */
            userName: string
            /** 이메일 정보 */
            emailVerificationId: string
            /** 비밀번호 */
            password: string
        },
        params: RequestParams = {}
    ) =>
        this.http.request<ResponseDto, Error>({
            path: `/user-service/api/v1/users/join`,
            method: 'POST',
            ...params,
            body: data,
        })
    /**
     * No description
     *
     * @tags login-controller
     * @name CardLogin
     * @summary 로그인
     * @request POST:/card-login
     * @response `200` `LoginDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    cardLogin = (
        query: {
            /** 카드키 */
            cardKey: string
        },
        params: RequestParams = {}
    ) =>
        this.http.request<LoginDto, Error>({
            path: `/card-login`,
            method: 'POST',
            query: query,
            ...params,
        })
    /**
     * No description
     *
     * @tags login-controller
     * @name GetCustomData
     * @summary customdata 용
     * @request GET:/info
     * @response `200` `CustomDataDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getCustomData = (params: RequestParams = {}) =>
        this.http.request<CustomDataDto, Error>({
            path: `/info`,
            method: 'GET',
            ...params,
        })
}
