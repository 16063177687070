import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { HttpStatus } from '../domain/const'

const handleResponse = (res: AxiosResponse) => {
    return res
}

const handleError = (error: any) => {
    if(!error.response){
        error = {
            response:{
                status: HttpStatus.SERVICE_UNAVAILABLE
            }
        }
    }
    return Promise.reject(error)
}
const commonHeaders = {
    accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
}
const api: AxiosInstance = axios.create({
    headers: commonHeaders,
})

api.interceptors.response.use(handleResponse, handleError)
export {api}
