import axios, { AxiosResponse } from 'axios'
import { NextApiResponse } from 'next'
import { LoginDto } from '../../../__generated__/data-contracts'
import loginController from '../../../dao/loginController'
import { HttpStatus } from '../../../domain/const'
import withSession, { NextIronRequest } from '../../../lib/session'
import { api } from '../../../utils/http'
import httpServer from '../../../dao/http-clients/httpServer'

export default withSession(async function handler(req: NextIronRequest, res: NextApiResponse): Promise<any> {
    const { method, body } = req
    // 로그인 했을 때
    if (method === 'POST') {
        const response = await loginController.login(body)
        const accessToken = response.headers.get('access-token')
        const refreshToken = response.headers.get('refresh-token');

        const data = response.data

        // 401 error
        if (response.status === HttpStatus.UNAUTHORIZATION) {
            req.session.destroy()
            return res.status(response.status).json(data)
        // 503 error
        } else if (response.status == HttpStatus.SERVICE_UNAVAILABLE) {
            return res.status(response.status).send('서버 긴급 점검')
        // 200 OK
        } else if (response.status == HttpStatus.OK) {
            // 여기선 새로 만든 api(로그인 후 사용자 정보 전달)로 메소드를 만들어서 리턴값을 받아와야 함
            // const data = await loginController.loginData()
            const data =
                {
                    access_token: accessToken,
                    userData: {
                        userId: 0,
                        loginId: '1@gmail.com',
                        userName: '테스트',
                        picture: null,
                        defaultFloorId: 1,
                        isCredentialsNonExpired: true
                    },
                    roleData: {
                        seat: 'SEAT',
                        roles: [ 'ROLE_USER', 'ROLE_ADMIN' ],
                        'meeting-room': 'MEETING_ROOM'
                    }
                }
            req.session.set('user', data)
            req.session.set('refreshToken', refreshToken)
            await req.session.save()
            return res.status(HttpStatus.OK).json(data)
        } else {
            return res.status(response.status).json(data)
        }
    // 로그아웃
    } else if (method === 'DELETE') {
        await req.session.destroy()
        return res.status(HttpStatus.OK).json({})
    // 로그인 정보 확인
    } else if (method === 'GET') {
        const user = req.session.get('user')
        if (user) {
            return res.status(HttpStatus.OK).json(user)
        } else {
            return res.status(HttpStatus.BAD_REQUEST).json(null)
        }
    // 리프레시 토큰을 이용한 엑세스 토큰 재발급
    } else if (method === 'PUT') {
        const refreshToken = req.session.get('refreshToken')
        if (refreshToken) {
            httpServer.setSecurityData(refreshToken)
            const response = await loginController.refresh()

            const newAccessToken = response.headers.get('access-token')
            let user = req.session.get('user')
            user.userData.access_token = newAccessToken

            const newRefreshToken = response.headers.get('refresh-token')
            req.session.set('refreshToken', newRefreshToken)

            return res.status(HttpStatus.OK).json(user)
        } else {
            return res.status(HttpStatus.BAD_REQUEST).json(null)
        }
    }

    res.setHeader('Allow', ['POST'])
    return res.status(HttpStatus.METHOD_NOT_ALLOW).end(`Method ${method} Not Allowed`)
})
const JWT_EXPIRED_TIME = 1800000;
const login = async (userName: string, password: string, token?: string) => {
    console.log(userName)
    const result = await api.post('/api/login', { email: userName, password: password, token: token })
    if (result.data.userData.access_token) {
        silentRefresh()
    }
    return result
}
const refresh = async () => {
    const result = await api.put('/api/login')
    if (result.data.userData.access_token) {
        silentRefresh()
    }
    return result
}
const silentRefresh = () => {
    setTimeout(refresh, JWT_EXPIRED_TIME - 60000);
}

const logout = () => {
    return api.delete('/api/login')
}

export { login, logout }
