import { Error, RegisterNotificationDto, ReqRegisterNotificationDto } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class NotificationController<SecurityDataType = unknown> {
    http: HttpClient<SecurityDataType>

    constructor(http: HttpClient<SecurityDataType>) {
        this.http = http
    }

    /**
     * No description
     *
     * @tags notification-controller
     * @name Register
     * @request POST:/api/notifications/register
     * @response `200` `RegisterNotificationDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    register = (data: ReqRegisterNotificationDto, params: RequestParams = {}) =>
        this.http.request<RegisterNotificationDto, Error>({
            path: `/api/notifications/register`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            ...params,
        })
    /**
     * No description
     *
     * @tags notification-controller
     * @name Unregister
     * @request DELETE:/api/notifications/delete/{token}
     * @response `200` `object` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    unregister = (token: string, params: RequestParams = {}) =>
        this.http.request<object, Error>({
            path: `/api/notifications/delete/${token}`,
            method: 'DELETE',
            ...params,
        })
}
